import React, { useState } from "react";
import axios from "axios";
import globalVariables from "../../GlobalVariable";

const ModalAllUserUpdate = ({ handleCloseModal, allUsers }) => {
  const [userData, setUserData] = useState({
    _id: "",
    name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const handleUserSelect = (e) => {
    const selectedUserId = e.target.value;
    const selectedUser = allUsers.find((user) => user._id === selectedUserId);

    if (selectedUser) {
      setUserData({
        _id: selectedUser._id,
        name: selectedUser.name,
        last_name: selectedUser.last_name,
        email: selectedUser.email,
        password: "", // La contraseña no debe pre-poblarse
      });
    } else {
      setUserData({
        _id: "",
        name: "",
        last_name: "",
        email: "",
        password: "",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .patch(`${globalVariables.domain}/user/`, userData, {
        body: {
          _id: userData._id,
          name: userData.name,
          last_name: userData.last_name,
          email: userData.email,
          password: userData.password,
          roles_ids: userData.roles_ids,
          business_yelp_ids: userData.business_yelp_ids,
          approved: userData.approved,
        },
      })
      .then((response) => {
        if (response.data.success) {
          handleCloseModal();
          alert("User updated successfully");
        } else {
          console.error("Error in response: ", response.data.message);
          alert("Error updating user: " + response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating user", error);
        alert("Error updating user");
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-11/12 md:w-1/3">
        <h2 className="text-lg font-semibold mb-4">Update User Data</h2>
        <div>
          <label
            htmlFor="roles_ids"
            className="block text-sm font-medium text-gray-700"
          >
            User
          </label>
          <select
            id="roles_ids"
            name="roles_ids"
            onChange={handleUserSelect}
            required
            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="" disabled selected>
              Select User
            </option>
            {allUsers.map((user) => (
              <option key={user._id} value={user._id}>
                {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
              </option>
            ))}
          </select>
        </div>
        <form onSubmit={handleSubmit}>
          <label className="block mb-2">
            Name:
            <input
              type="text"
              name="name"
              value={userData.name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <label className="block mb-2">
            Last Name:
            <input
              type="text"
              name="last_name"
              value={userData.last_name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <label className="block mb-2">
            Email:
            <input
              type="email"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <label className="block mb-2">
            Password:
            <input
              type="password"
              name="password"
              value={userData.password}
              onChange={handleInputChange}
              className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
            />
          </label>
          <div className="flex flex-row justify-between gap-2">
            <button
              onClick={handleCloseModal}
              className="w-full bg-orange-500 text-white py-2 rounded hover:bg-orange-600"
            >
              Close
            </button>
            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalAllUserUpdate;
