import React from "react";

const ModalCreateUser = ({
  successMessage,
  handleSubmit,
  setFormData,
  formData,
  setShowModal,
  roles,
  businesses,
  responseSuccess,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="fixed inset-0 py-4 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white p-6 overflow-auto h-full rounded-lg shadow-lg w-full max-w-md">
        {successMessage && (
          <div
            className={`mb-4 p-2 ${
              responseSuccess
                ? "text-green-700 bg-green-100 border border-green-300"
                : "text-red-700 bg-red-100 border border-red-300"
            } rounded`}
          >
            {successMessage}
          </div>
        )}
        <div className="flex flex-row justify-between items-center mb-4">
          <h2 className="text-xl font-semibold ">Create User</h2>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Namem
            </label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              id="last_name"
              name="last_name"
              type="text"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email address"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="roles_ids"
              className="block text-sm font-medium text-gray-700"
            >
              Role
            </label>
            <select
              id="roles_ids"
              name="roles_ids"
              onChange={(e) =>
                setFormData({ ...formData, roles_ids: [e.target.value] })
              }
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" disabled selected>
                Select a role
              </option>
              {roles.map((role) => (
                <option key={role._id} value={role._id}>
                  {role.role_name.charAt(0).toUpperCase() +
                    role.role_name.slice(1)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="business_yelp_ids"
              className="block text-sm font-medium text-gray-700"
            >
              Business
            </label>
            <select
              id="business_yelp_ids"
              name="business_yelp_ids"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  business_yelp_ids: [e.target.value],
                })
              }
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="">Select a business</option>
              {businesses.map((business) => (
                <option
                  key={business.business_yelp_id}
                  value={business.business_yelp_id}
                >
                  {business.business_name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col sm:flex-row justify-between gap-4">
            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="w-full sm:w-auto px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full sm:w-auto px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Create User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModalCreateUser;
